$(document).on('turbolinks:load', function() {
  function formatPhoneNumber(event) {
    var textField;
    textField = event.target;
    if (("1234567890".includes(event.key) === false || textField.value.length === 14) && event.keyCode !== 8) {
      return event.preventDefault();
    } else {
      return textField.value = textField.value.replace(/^\(?([0-9][0-9][0-9]){1}\)?-?([0-9][0-9][0-9]){1}-?([0-9][0-9][0-9]){1}$/, '($1)-$2-$3');
    }
  }

  $('#phone_').keydown(function(event) {
    return formatPhoneNumber(event);
  });
  $('#phone_').change(function(event) {
    return formatPhoneNumber(event);
  });
  $('#phone_').on('paste', function(event) {
    return formatPhoneNumber(event);
  });
  return $('#contact_form').on('submit', function() {
    $('#submit-button-container').attr("hidden", true);
    return $('#spinner-container').attr("hidden", false);
  });
});